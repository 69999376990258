module.exports = {
  title: 'Couple Code therapy, learning webdevelopment for absolute beginner',
  description: '',
  url: 'https://couplecodetherapy.com',
  image: 'https://couplecodetherapy.com/og/og-image.jpg',
  logo: 'https://couplecodetherapy.com/icons/android-chrome-512x512.png',
  twitter: '@cplecodetherapy',
  fbAppID: '',
  author: {
    minibio: `
      Join Couple code therapy where Khaled tries to teach his dear partner Amal how to code so she can make changes to her website. This show will be full of web development tips and trick for absolute beginner
    `
  }
}
